<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared.  </p>
        <ul>
          <li class="pb-3">Seligman, M. E. P., Steen, T. A., Park, N., & Peterson, C. (2005). Positive psychology progress: Empirical validation of interventions. <em>American Psychologist, 60</em>(5), 410-421. <a href="https://pubmed.ncbi.nlm.nih.gov/16045394" target="_blank">pubmed.ncbi.nlm.nih.gov/16045394</a>.</li>
          <li class="pb-3">Keltner, D., Haidt, J., (2003). Approaching awe, a moral spiritual and esthetic emotion. Cognition and Emotion 17 (2). 297-314 <a href="https://greatergood.berkeley.edu/dacherkeltner/docs/keltner.haidt.awe.2003.pdf " target="_blank">greatergood.berkeley.edu/dacherkeltner/docs/keltner.haidt.awe.2003.pdf </a></li>
          <li class="pb-3">Gallagher, M.W., Marques, S.C. & Lopez, S.J. (2017) Hope and the academic trajectory of college students. J Happiness Stud 18, 341–352. <a href="https://doi.org/10.1007/s10902-016-9727-z" target="_blank">doi.org/10.1007/s10902-016-9727-z</a> </li>
          <li class="pb-3">Parashar, F. (2009, October 14). <em>The Psychology of Optimism and Pessimism: Theories and Research</em> Findings.<br> <a href="http://positivepsychology.org.uk/optimism-pessimism-theory/ " target="_blank">positivepsychology.org.uk/optimism-pessimism-theory/ </a> </li>
          <li class="pb-3">Shapiro, S. L., Schwartz, G. E., & Santerre, C. (2002). Meditation and positive psychology. <em>Handbook of positive psychology</em>, 2, 632-645. doi: 10.1093/oxfordhb/9780199396511.013.50 </li>
          <li class="pb-3">Davies, S. T., (2017, March 11). <em>The 20-Second Rule: How to Build Better Habits (in 1/3 of a Minute)</em>. Sam Thomas Davies <br><a href="https://www.samuelthomasdavies.com/the-20-second-rule/" target="_blank">www.samuelthomasdavies.com/the-20-second-rule/</a>  </li>
          <li class="pb-3">Carney, C., (2020, May 20) <em>The DOZE App: A Unique Approach to Overcoming Sleep Problems in Young Adults</em>. Scientia <br><a href="https://www.scientia.global/dr-colleen-carney-the-doze-app-a-unique-approach-to-overcoming-sleep-problems-in-young-adults/" target="_blank">www.scientia.global/dr-colleen-carney-the-doze-app-a-unique-approach-to-overcoming-sleep-problems-in-young-adults/</a>  </li>
          <li class="pb-3">Eva, A. L. (2017, November 28) <em>Why we should embrace mistakes in school</em>. If students can see the beauty in spilled milk, research suggests they’ll learn better. Greater Good Berkeley. <a href="https://greatergood.berkeley.edu/article/item/why_we_should_embrace_mistakes_in_school" target="_blank">greatergood.berkeley.edu/article/item/why_we_should_embrace_mistakes_in_school</a> </li>
          <li class="pb-3">Shapiro, S. L., Schwartz, G. E., & Santerre, C. (2002). Meditation and positive psychology. Handbook of positive psychology, 2, 632-645. DOI: 10.1093/oxfordhb/9780199396511.013.50 <a href="https://www.researchgate.net/profile/Hooria_Jazaieri/publication/329718661_Meditation_and_Positive_Psychology/links/5c1826bb299bf139c76060d5/Meditation-and-Positive-Psychology.pdf " target="_blank">www.researchgate.net/profile/Hooria_Jazaieri/publication/329718661_Meditation_and_Positive_Psychology/<br>links/5c1826bb299bf139c76060d5/Meditation-and-Positive-Psychology.pdf </a> </li>
          <li class="pb-3">Neff, K. (2011). Self‐Compassion, self-esteem, and well-being. <em>Social and Personality Psychology Compass, 5</em>(1), 1-12. doi:10.1111/j.1751-9004.2010.00330.x </li>
          <li class="pb-3">Weir, K. (2012). <em>What you need to know about Willpower: The Psychological Science of Self-Control</em>. American Psychological Association website <a href="https://www.apa.org/topics/personality/willpower" target="_blank">www.apa.org/topics/personality/willpower</a> </li>
          <li class="pb-3">Duckworth, A. l., Gendler, T. S., Gross, J. J. (2016) Situational strategies for self-control. <em>Perspectives on Psychological Science, 11</em>(1), 35–55, <a href="https://journals.sagepub.com/doi/10.1177/1745691615623247" target="_blank">journals.sagepub.com/doi/10.1177/1745691615623247</a>  </li>
          <li class="pb-3">Galla, B. M., Shulman, E. P., Plummer, B. D., Gardner, M., Hutt, S. J., Goyer, J. P., D’Mello, S. K., Finn, A. S., & Duckworth, A. L. (2019). Why high school grades are better predictors of on-time college graduation than are admissions test scores: The roles of self-regulation and cognitive ability. <em>American Educational Research Journal, 56</em>(6), 2077-2115. <a href="https://doi.org/10.3102/0002831219843292" target="_blank">doi.org/10.3102/0002831219843292</a> </li>
          <li class="pb-3">Marano, H.E. (1999). <em>The power of play. Psychology Today.</em> <br><a href="https://www.psychologytoday.com/ca/articles/199907/the-power-play" target="_blank">www.psychologytoday.com/ca/articles/199907/the-power-play</a>  </li>
          <li class="pb-3">Schwartz, B. (2004). <em>The tyranny of choice</em>. Scientific American. (pp. 71-75)</li>
          <li class="pb-3">Rattan, A., Savani, K., Chugh, D., & Dweck, C. S. (2015). Leveraging mindsets to promote academic achievement: Policy recommendations. Perspectives on Psychological Science, 10(6), 721-726. doi:10.1177/1745691615599383 </li>
          <li class="pb-3">Dweck, C. (2015, September 22). <em>Carol Dweck revisits the ‘growth mindset’</em>. Education Week.<br> <a href="https://www.edweek.org/ew/articles/2015/09/23/carol-dweck-revisits-the-growth-mindset.html" target="_blank">www.edweek.org/ew/articles/2015/09/23/carol-dweck-revisits-the-growth-mindset.html</a>  </li>
          <li class="pb-3">Samsom, L., Bretherton, L., Neimic, N. (2016). PsycCRITIQUES Vol. 61, No. 25, Article 10, American Psychological Association. Doing the Right Thing: Character, Moral Goodness, and Star Wars</li>
          <li class="pb-3">Lavy, S. (2020). A Review of Character Strengths Interventions in Twenty-First-Century Schools: Their importance and how they can be fostered. <em>Applied Research Quality Life, 15</em>, 573–596. <br><a href="https://doi.org/10.1007/s11482-018-9700-6" target="_blank">doi.org/10.1007/s11482-018-9700-6</a>  </li>
          <li class="pb-3">Brecher, D. (2021). Mindset Toolkit. </li>
          <li class="pb-3">Canning, E. A., Muenks, K., Green, D. J., & Murphy, M. C. (2019). STEM faculty who believe ability is fixed have larger racial achievement gaps and inspire less student motivation in their classes. <em>Science Advances, 5</em>(2). <a href="https://doi.org/10.1126/sciadv.aau4734" target="_blank">doi.org/10.1126/sciadv.aau4734</a> </li>
          <li class="pb-3">Brecher, D. (2020). Feedback to encourage resilience.</li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
